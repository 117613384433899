import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { VSeparator } from "components/separator/Separator";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../../../constants";
import {getAuthToken} from "../../../../helpers/helpers"

export const pieChartOptions = {
  labels: ["Diabète", "HTA", "Grossesse", "Allaitement", "Aucune Pathologie"],
  colors: ["#0066FF", "#33FFFF", "#0000CC", "#B3E5FC", "#90CAF9"],
  chart: {
    width: "50px",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    donut: {
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: ["#0066FF", "#33FFFF", "#0000CC", "#B3E5FC", "#90CAF9"],
  },
  tooltip: {
    enabled: true,
    theme: "dark",
  },
};

export default function Conversion({ selectedDate, ...rest }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Ajout d'un état de chargement

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Démarre le chargement
      let diab = 0;
      let HTA = 0;
      let enc = 0;
      let allait = 0;
      let sain = 0;
  
      const token = getAuthToken();

      try {
        const response = await axios.get(
          `${API_URL}/api/track/action/AFFICHER_TRAITEMENT`,
          {
            headers: {
              "x-access-token": token,
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        const filteredData = response.data.filter((item) => {
          const createdAt = new Date(item.created_at);
          if (selectedDate) {
            return createdAt.toISOString().split("T")[0] === selectedDate;
          }
          return true;
        });
  
        let total = filteredData.length;
  
        filteredData.forEach((item) => {
          if (item.data.patientProfile.profileProperties.femme_enceinte) {
            enc++;
          }
          if (item.data.patientProfile.profileProperties.allaitement) {
            allait++;
          }
          if (item.data.patientProfile.profileProperties.hta) {
            HTA++;
          }
          if (item.data.patientProfile.profileProperties.diabete) {
            diab++;
          }
          if (
            !item.data.patientProfile.profileProperties.femme_enceinte &&
            !item.data.patientProfile.profileProperties.allaitement &&
            !item.data.patientProfile.profileProperties.hta &&
            !item.data.patientProfile.profileProperties.diabete
          ) {
            sain++;
          }
        });
  
        const diabPercentage = total > 0 ? (diab / total) * 100 : 0;
        const HTAPercentage = total > 0 ? (HTA / total) * 100 : 0;
        const encPercentage = total > 0 ? (enc / total) * 100 : 0;
        const allaitPercentage = total > 0 ? (allait / total) * 100 : 0;
        const sainPercentage = total > 0 ? (sain / total) * 100 : 0;
  
        setChartData([
          diabPercentage,
          HTAPercentage,
          encPercentage,
          allaitPercentage,
          sainPercentage,
        ]);
      } catch (error) {
        console.log("Error making the request:", error);
        setChartData([0, 0, 0, 0, 0]);
      }
      setIsLoading(false); // Terminé le chargement
    };
  
    fetchData();
  }, [selectedDate]); // Ensure selectedDate is added as a dependency
  
  if (isLoading) {
    return <div>Chargement...</div>; // Ajout d'un indicateur de chargement
  }

  if (chartData === null) {
    return <div>Pas données disponibles</div>; // Message si pas de données
  }
  return (
    <Card mx={{ base: "0", xl: "50px" }} p="20px" align="center" direction="column" w={{sm: "360%", md: "100%"  , xl :"390%"}}  {...rest}>

        <Flex
          direction="column"
          align="center"
          justify="center"
          w="100%"
        >
          <Text
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="20px"
          >
            Distribution des profils patients
          </Text>
          <PieChart chartData={chartData} chartOptions={pieChartOptions} />
          <Flex
            direction="column"
            align="center"
            justify="center"
            mt="20px"
            w="100%"
          >
            <Flex justify="center" wrap="wrap" gap="20px">
              <Flex align="center" gap="10px">
                <Box bg="#0066FF" h="10px" w="10px" borderRadius="50%" />
                <Text color={textColor} fontSize="md" fontWeight="500">
                  Diabète
                </Text>
                <Text color={textColor} fontSize="md" fontWeight="700">
                  {chartData[0].toFixed(2)}%
                </Text>
              </Flex>
              <Flex align="center" gap="10px">
                <Box bg="#33FFFF" h="10px" w="10px" borderRadius="50%" />
                <Text color={textColor} fontSize="md" fontWeight="500">
                  HTA
                </Text>
                <Text color={textColor} fontSize="md" fontWeight="700">
                  {chartData[1].toFixed(2)}%
                </Text>
              </Flex>
              <Flex align="center" gap="10px">
                <Box bg="#0000CC" h="10px" w="10px" borderRadius="50%" />
                <Text color={textColor} fontSize="md" fontWeight="500">
                  Grossesse
                </Text>
                <Text color={textColor} fontSize="md" fontWeight="700">
                  {chartData[2].toFixed(2)}%
                </Text>
              </Flex>
              <Flex align="center" gap="10px">
                <Box bg="#B3E5FC" h="10px" w="10px" borderRadius="50%" />
                <Text color={textColor} fontSize="md" fontWeight="500">
                  Allaitement
                </Text>
                <Text color={textColor} fontSize="md" fontWeight="700">
                  {chartData[3].toFixed(2)}%
                </Text>
              </Flex>
              <Flex align="center" gap="10px">
                <Box bg="#90CAF9" h="10px" w="10px" borderRadius="50%" />
                <Text color={textColor} fontSize="md" fontWeight="500">
                  Aucune Pathologie
                </Text>
                <Text color={textColor} fontSize="md" fontWeight="700">
                  {chartData[4].toFixed(2)}%
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    );
    
}
