import React from "react";
import {
  Flex,
  Image,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import MedicDetails from "../../default/components/MedicCard";

export default function NFT(props) {
  const { image, name, forme, text, price, date_peremption, id } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );

  return (
    <>
      <Card
        _hover={bgItem}
        bg="transparent"
        boxShadow="unset"
        px="24px"
        py="50px"
        transition="0.2s linear"
        onClick={onOpen} // Rendre l'ensemble cliquable
        cursor="pointer" // Indiquer visuellement qu'il est cliquable
      >
        <Flex direction={{ base: "column" }} justify="center">
          <Flex position="relative" align="center">
            <Image src={image} w="66px" h="66px" borderRadius="20px" me="16px" />
            <Flex
              direction="column"
              w={{ base: "70%", md: "100%" }}
              me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
            >
              <Text
                color={textColor}
                fontSize={{ base: "md" }}
                mb="5px"
                fontWeight="bold"
                me="14px"
              >
                {name}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize={{ base: "sm" }}
                fontWeight="400"
                me="14px"
              >
                {forme}
              </Text>
              {date_peremption && (
                <Text
                  color="secondaryGray.600"
                  fontSize={{ base: "sm" }}
                  fontWeight="400"
                  me="14px"
                >
                  Date de péremption : {date_peremption}
                </Text>
              )}
            </Flex>
            <Flex
              me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
              align="center"
            >
              <Text fontWeight="700" fontSize="md" color={textColor}>
                {price} {text}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="fit-content">
          <ModalCloseButton />
          <ModalBody p={0}>
            <MedicDetails width="600px" id={id} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
