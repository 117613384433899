import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  Avatar,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  RadioGroup,
  Radio,
  Stack,
  IconButton,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { API_URL } from "../../../constants"; // Ensure this is properly imported
import axios from "axios";
import { getAuthToken } from "../../../helpers/helpers"; // Ensure you have this utility function for retrieving the token
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function Overview() {
  const [profiles, setProfiles] = useState([]);
  const [currentProfile, setCurrentProfile] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Modif, setModif]=useState(false)
  const [currentCode_oprerateur, setcurrentCode_oprerateur] = useState()
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedCodeOperateur, setSelectedCodeOperateur] = useState(null);
  const token = getAuthToken();
  const [showCode, setShowCode] = useState(false);

  
  
  // Fetch profiles from API
  const fetchProfiles = async () => {
    try {
      const userId = localStorage.getItem("UserID");
      const response = await axios.get(`${API_URL}/api/profiles/${userId}`, {
        headers: {
          "x-access-token": token,
        },
        Authorization: `Bearer ${localStorage.getItem("token")}`
      });
      setProfiles(response.data);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  };

  // Call fetchProfiles when the component mounts
  useEffect(() => {
    fetchProfiles();
  }, []); // Empty dependency array to run this once when the component mounts

  const handleOpen = (profile = null, modifval) => {
    setModif(modifval || false)
    if(profile) setcurrentCode_oprerateur(profile.code_operateur)
    setCurrentProfile(
      profile || {
        id: localStorage.getItem("UserID"),
        photo: "",
        nom_operateur: "",
        genre: "Homme",
        code_operateur: "",
        email: "",
        telephone: "",
        date_naissance: "",
      }
    );
    onOpen();
  };
  const maskString = (str, maskChar = "*") => {
    return str ? maskChar.repeat(str.length) : "";
  };
  const handleSave = async () => {
    try {
      const isCodeOperateurExists = profiles.some(
        (profile) =>
          profile.code_operateur === currentProfile.code_operateur && profile.id !== currentProfile.id
      );
  
      if (isCodeOperateurExists) {
        setErrorMessage("Le code opérateur existe déjà.");
        return;
      }
      setErrorMessage("");
      if (Modif) {
        // Update existing profile
        await axios.put(
          `${API_URL}/api/update_profile`,
          {
            userId: localStorage.getItem("UserID"),
            code_operateur: currentCode_oprerateur,
            updatedData: currentProfile,
          },
          {
            headers: {
              "x-access-token": token,
            },
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        );
        fetchProfiles()
      } else {
        // Create new profile
        await axios.post(
          `${API_URL}/api/create_profile`,
          {
            userId: currentProfile.id,
            profileData: currentProfile,
          },
          {
            headers: {
              "x-access-token": token,
            },
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        );
        fetchProfiles()
      }
      onClose();
      setModif(false)
    } catch (error) {
      console.error("Error saving profile:", error);
    }
  };
  
  const confirmDelete = (codeOperateur) => {
    setSelectedCodeOperateur(codeOperateur);
    setIsConfirmOpen(true);
  };
  const history = useHistory();

  const handleRowClick = (id) => {
    history.push("/admin/historique-vente", { id });
  };
  const handleDeleteConfirmed = () => {
    if (selectedCodeOperateur) {
      handleDelete(selectedCodeOperateur);
      setIsConfirmOpen(false);
      setSelectedCodeOperateur(null);
    }
  };
  
  const handleCancelDelete = () => {
    setIsConfirmOpen(false);
    setSelectedCodeOperateur(null);
  };

  const handleDelete = async (profileId) => {
    try {
      await axios.delete(`${API_URL}/api/delete_profile`, {
        data: { userId: localStorage.getItem("UserID"), profileId:profileId }, // Adjust the delete payload as needed
        headers: {
          "x-access-token": token,
        },
        Authorization: `Bearer ${localStorage.getItem("token")}`
      });
      fetchProfiles()
    } catch (error) {
      console.error("Error deleting profile:", error);
    }
  };

  return (
    <Box pt={{ base: "140px", md: "80px", xl: "80px" }}>
      {/* Profiles Section */}
      <Box maxWidth={{ base: "100%", lg: "100%" }} mx="auto" overflowX="auto" p={4} boxShadow="md" borderRadius="lg" >
        <Text fontSize="xl" fontWeight="bold" mb={4} textAlign="center">
          Liste des Profils
        </Text>
        <Button colorScheme="teal" onClick={() => handleOpen()} mb={4}>
          Ajouter un nouveau profil
        </Button>
        <Table variant="simple" size="md">
          <Thead >
            <Tr>
              <Th>Photo</Th>
              <Th>Nom de l’opérateur</Th>
              <Th>Genre</Th>
              <Th>
            Code opérateur
            <IconButton
              size="sm"
              ml={2}
              aria-label={showCode ? "Mask code" : "Show code"}
              icon={showCode ? <ViewOffIcon /> : <ViewIcon />}
              onClick={() => setShowCode(!showCode)}
            />
          </Th>
              <Th>Email</Th>
              <Th>Numéro téléphone</Th>
              <Th>Date de naissance</Th>
              <Th>Score</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {profiles.map((profile) => (
              <Tr
                key={profile._id}
                _hover={{ bg: "gray.100" }}
                cursor="pointer"
                onClick={() => handleRowClick(profile._id)}
              >
                <Td>
                  <Avatar src={profile.photo} name={profile.nom_operateur} />
                </Td>
                <Td>{profile.nom_operateur}</Td>
                <Td>{profile.genre}</Td>
                <Td>{showCode ? profile.code_operateur : maskString(profile.code_operateur)}</Td>
                <Td>{profile.email || "—"}</Td>
                <Td>{profile.telephone || "—"}</Td>
                <Td>{new Date(profile.date_naissance).toISOString().split("T")[0] || "—"}</Td>
                <Td>{profile.points}</Td>
                <Td>
                  <Button size="sm" colorScheme="blue" onClick={(e) => { e.stopPropagation(); handleOpen(profile, true); }}>
                    Modifier
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="red"
                    ml={2}
                    onClick={(e) => { e.stopPropagation(); confirmDelete(profile._id); }}
                  >
                    Supprimer
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Modal isOpen={isConfirmOpen} onClose={handleCancelDelete}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Êtes-vous sûr de vouloir supprimer cet opérateur ?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleDeleteConfirmed}>
              Oui, supprimer
            </Button>
            <Button variant="ghost" ml={3} onClick={handleCancelDelete}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Modal for Adding/Editing Profiles */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{Modif ? "Modifier Profil" : "Ajouter un Profil"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing="10px">
            {errorMessage && (
              <Text color="red.500" fontSize="sm">
                {errorMessage}
              </Text>
            )}
              <Input
                placeholder="Nom de l’opérateur (Obligatoire)"
                value={currentProfile?.nom_operateur || ""}
                onChange={(e) =>
                  setCurrentProfile({ ...currentProfile, nom_operateur: e.target.value })
                }
              />
              <RadioGroup
                value={currentProfile?.genre || "Homme"}
                onChange={(value) =>
                  setCurrentProfile({ ...currentProfile, genre: value })
                }
              >
                <Stack direction="row">
                  <Radio value="Homme">Homme</Radio>
                  <Radio value="Femme">Femme</Radio>
                </Stack>
              </RadioGroup>
              <Input
                placeholder="Code opérateur (Obligatoire)"
                value={currentProfile?.code_operateur || ""}
                onChange={(e) =>
                  setCurrentProfile({ ...currentProfile, code_operateur: e.target.value })
                }
              />
              <Input
                placeholder="Adresse email (Facultatif)"
                value={currentProfile?.email || ""}
                onChange={(e) =>
                  setCurrentProfile({ ...currentProfile, email: e.target.value })
                }
              />
              <Input
                placeholder="Numéro téléphone (Facultatif)"
                value={currentProfile?.telephone || ""}
                onChange={(e) =>
                  setCurrentProfile({ ...currentProfile, telephone: e.target.value })
                }
              />
              <Input
                type="date"
                placeholder="Date de naissance (Facultatif)"
                value={currentProfile?.date_naissance || ""}
                onChange={(e) =>
                  setCurrentProfile({ ...currentProfile, date_naissance: e.target.value })
                }
              />
              <Input
                placeholder="URL de la photo (Facultatif)"
                value={currentProfile?.photo || ""}
                onChange={(e) =>
                  setCurrentProfile({ ...currentProfile, photo: e.target.value })
                }
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSave} mr="3">
              Sauvegarder
            </Button>
            <Button onClick={onClose}>Annuler</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
