import React, { useState, useEffect } from "react";
import axios from "axios";
import { 
  Flex, Heading, Spinner, Center, Image, Text, Box, Stack, Divider, Select, Input, IconButton,Avatar
} from "@chakra-ui/react";
import { DeleteIcon  } from "@chakra-ui/icons";
import { API_URL } from "../../../constants";
import { getAuthToken } from "../../../helpers/helpers";
import defaultImage from "assets/img/meds.png";
import { useLocation } from "react-router-dom";

export default function ProfileHistory() {
  const location = useLocation();
  const profileId = location.state?.id; 

  const [groupedHistory, setGroupedHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profiles, setProfiles] = useState([]); 
  const [selectedProfileId, setSelectedProfileId] = useState(() => profileId || null);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    const fetchProfiles = async () => {
      const token = getAuthToken();
      try {
        const userId = localStorage.getItem("UserID");
        const response = await axios.get(`${API_URL}/api/profiles/${userId}`, {
          headers: {
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
          },
        });

        setProfiles(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des profils:", error);
      }
    };

    fetchProfiles();
  }, [profileId]); 

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = getAuthToken();
      try {
        const response = await axios.get(`${API_URL}/api/cart`, {
          headers: {
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
          },
        });

        let carts = response.data;

        if (selectedProfileId) {
          carts = carts.filter((cart) => cart.profileId?.["_id"] && cart.profileId._id.toString() === selectedProfileId.toString());
        }

        if (selectedDate) {
          carts = carts.filter((cart) => new Date(cart.created_at).toISOString().split('T')[0] === selectedDate);
        }

        const historyMap = carts.reduce((acc, cart) => {
          const dateKey = new Date(cart.created_at).toLocaleDateString();
          const items = cart.items.map((item) => ({
            specialite: item.name,
            image: item.medicamentDetails?.photo || defaultImage,
            price: item.medicamentDetails?.prix || 0, 
            date_peremption: item.medicamentDetails?.date_peremption,
            points: item.point || 0, 
            profileName:cart.profileId?.nom_operateur || 'Opérateur inconnu',
            profilePhoto :cart.profileId?.photo || ""

          }));
          
          if (!acc[dateKey]) acc[dateKey] = { items: [], totalPrice: 0, totalPoints: 0 };
          acc[dateKey].items.push(...items);
          acc[dateKey].totalPrice += items.reduce((sum, item) => sum + Number(item.price), 0);
          acc[dateKey].totalPoints += items.reduce((sum, item) => sum + item.points, 0);

          return acc;
        }, {});

        const sortedHistory = Object.entries(historyMap)
          .map(([date, data]) => ({
            date,
            items: data.items,
            totalPrice: data.totalPrice,
            totalPoints: data.totalPoints,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date));

        setGroupedHistory([...sortedHistory].reverse());
      } catch (error) {
        console.error("Erreur lors de la récupération de l'historique:", error);
        setGroupedHistory([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedProfileId, selectedDate]); 

  const resetFilters = () => {
    setSelectedProfileId(null);
    setSelectedDate("");
  };

  return (
    <Flex flexDirection="column" py="30px" px="20px" mt={10}>
      <Flex justifyContent="space-between" alignItems="center" mb="20px">
        <Heading size="lg" color="blue.600">
          🛒 Historique des Ventes
        </Heading>
        <Flex>
          <Select 
            w="300px" 
            value={selectedProfileId || ""} 
            onChange={(e) => setSelectedProfileId(e.target.value ? e.target.value : null)}
          >
            <option value="">Tous les profils</option>
            {profiles.map((profile) => (
              <option key={profile._id} value={profile._id}>
                {profile.nom_operateur} 
              </option>
            ))}
          </Select>
          <Input 
            type="date" 
            value={selectedDate} 
            onChange={(e) => setSelectedDate(e.target.value)}
            ml={4}
          />
          <IconButton
            ml={4}
            aria-label="Reset filters"
            icon={<DeleteIcon />}
            onClick={resetFilters}
          />
        </Flex>
      </Flex>
      
      {loading ? (
        <Center h="50vh">
          <Spinner size="lg" color="blue.500" />
        </Center>
      ) : groupedHistory.length > 0 ? (
        groupedHistory.map(({ date, items, totalPrice, totalPoints }) => (
          <Box key={date} mb="20px">
            <Heading size="md" color="gray.700" mb="15px">
              📅 {date}     💰 Total: <strong>{Number(totalPrice).toFixed(2)} TND</strong>  💊 Points: <strong>{totalPoints}</strong>
            </Heading>        
            {items.map((item, index) => (
              <Box key={index} p="15px" borderRadius="10px" bg="white" boxShadow="md" mb="12px">
                <Flex alignItems="center">
                  <Image src={item.image} alt={item.specialite} boxSize="60px" borderRadius="full" mr="15px" />
                  <Stack spacing={1} w="100%">
                    <Text fontWeight="bold" fontSize="lg" color="blue.700">
                      {item.specialite}
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      💰 Prix : <strong>{item.price} TND</strong>
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                      ⏳ Expire le : {item.date_peremption || "N/A"}
                    </Text>
                    <Flex align="center" mt={2}>
                      <Avatar src={item.profilePhoto} name={item.profileName} size="sm" mr={2} />
                      <Text fontSize="xs" fontWeight="bold" color="gray.600">{item.profileName}</Text>
                    </Flex>
                  </Stack>
                </Flex>
              </Box>
            ))}
            <Divider mt="10px" />
          </Box>
        ))
      ) : (
        <Text textAlign="center" fontSize="lg" color="gray.500">❌ Aucun historique disponible</Text>
      )}
    </Flex>
  );
}
