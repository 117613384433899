import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { IoHeart, IoSettingsSharp } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import { IoMdCart } from "react-icons/io";
import { IoCalendarSharp } from "react-icons/io5";
import { CiCreditCardOff } from "react-icons/ci";

import MainDashboard from "views/admin/default";
import vendus from "views/admin/prodPlusVendus";
import Properimes from "medicProperimes";
import Profile from "views/admin/profile";
import Settings from "views/admin/Settings";
import Favoris from "views/admin/favourite";
import Masked from "views/admin/masked";
import SignInCentered from "views/auth/signIn";
import WithoutDatePeremption from 'views/admin/without_datePeremption'
import ProfileHistory from "views/admin/profile/profileHistory"
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Produits les plus vendus",
    layout: "/admin",
    path: "/produits-vendus",
    icon: <Icon as={IoMdCart} width="20px" height="20px" color="inherit" />,
    component: vendus,
    secondary: true,
  },
  {
    name: "Produits favoris",
    layout: "/admin",
    icon: <Icon as={IoHeart} width="20px" height="20px" color="inherit" />,
    path: "/produits-favoris",
    component: Favoris,
  },{
    name: "Produits Sans date_peremption",
    layout: "/admin",
    icon: <Icon as={CiCreditCardOff} width="20px" height="20px" color="inherit" />,
    path: "/produits-sans-date_peremption",
    component: WithoutDatePeremption,
  },
  {
    name: "Produits masqués",
    layout: "/admin",
    icon: <Icon as={FaEyeSlash} width="20px" height="20px" color="inherit" />,
    path: "/produits-masques",
    component: Masked,
  },
  {
    name: "Produits propérimés",
    layout: "/admin",
    path: "/produits-properimes",
    icon: (
      <Icon as={IoCalendarSharp} width="20px" height="20px" color="inherit" />
    ),
    component: Properimes,
    secondary: true,
  },
  {
    name: "Paramètres",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={IoSettingsSharp} width="20px" height="20px" color="inherit" />,
    component: Settings,
  },
  {
    layout: "/admin",
    path: "/profile",
    component: Profile,
  },
  {
    layout: "/auth",
    path: "/sign-in",
    component: SignInCentered,
  },
  {
    layout: "/admin",
    path: "/historique-vente",
    component: ProfileHistory,
  },
];

export default routes;
