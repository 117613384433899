import React from 'react';
import { Box, Text, Grid, useBreakpointValue } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

export default function Settings() {
    const history = useHistory();
    const handleNavigation = (path) => {
        history.push(path);
    };

    const gridTemplateColumns = useBreakpointValue({
        base: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
    });

    return (
        <div style={{ marginTop: '64px' }}>
            <Grid templateColumns={gridTemplateColumns} gap={6} px={4}>
                <Box
                    p={6}
                    borderWidth={1}
                    borderRadius="xl"
                    boxShadow="lg"
                    cursor="pointer"
                    transition="all 0.3s ease"
                    _hover={{
                        transform: 'scale(1.05)',
                        boxShadow: '2xl',
                        bg: 'gray.50',
                    }}
                    _active={{
                        transform: 'scale(1)',
                        bg: 'gray.100',
                    }}
                    onClick={() => handleNavigation('/admin/profile')}
                >
                    <Text fontSize="lg" fontWeight="bold" color="teal.500">
                        Gérez vos profils!
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                        Créer et gérer les profils des pharmaciens/préparateurs
                    </Text>
                </Box>
            </Grid>
        </div>
    );
}
