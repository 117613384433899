import React, { useEffect, useState } from "react";
import axios from "axios";
import { Flex, Button } from "@chakra-ui/react";
import HistoryItem from "views/admin/prodPlusVendus/components/HistoryItem";
import defaultImage from "assets/img/meds.png";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { API_URL } from "./constants";
import { getAuthToken } from "./helpers/helpers";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function Properimes() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = getAuthToken();

      try {
        const response = await axios.get(
          `${API_URL}/api/properemption_medicament`,
          {
            headers: {
              "x-access-token": token,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let acc = {};
        response.data.forEach((item) => {
          const id = item.med_id;
          const itemId = item?._id;
          const itemName = item?.specialite;
          const itemImage = item.photo;
          const itemForme = item?.forme;
          const itemIsFavorite = item?.isFavourite;
          const itemQte_stock = item?.qte_stock;
          var date_peremption = item?.date_peremption;
          const date = new Date(date_peremption);
          date_peremption = date.toLocaleDateString("fr-FR");

          if (!Object.keys(acc).includes(itemName)) {
            acc[itemName] = {
              id: id,
              itemId: itemId,
              image: itemImage,
              forme: itemForme,
              isFavourite: itemIsFavorite,
              qte_stock: itemQte_stock,
              date_peremption: date_peremption,
            };
          }
        });
        const sortedCounts = Object.entries(acc).sort(
          (a, b) => b[1].qte_stock - a[1].qte_stock
        );
        const prodProperimes = sortedCounts.map(([name, details]) => ({
          id: details.id,
          name: name,
          image: details.image,
          forme: details.forme,
          date_peremption: details.date_peremption,
          itemId: details.itemId,
          isFavourite: details.isFavourite,
          qte_stock: details.qte_stock,
        }));
        setProducts(prodProperimes);
      } catch (error) {
        setProducts([]);
      }
    };

    fetchData();
  }, []);

  const handleFavouriteChange = async (item) => {
    const token = getAuthToken();
    try {
      const newFavouriteStatus = !item.isFavourite;

      const response = await axios.post(
        `${API_URL}/api/update_medicine`,
        {
          medicineId: item.itemId,
          isFavourite: newFavouriteStatus,
        },
        {
          headers: {
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setProducts((prevProducts) =>
          prevProducts.map((currentItem) =>
            currentItem.itemId === item.itemId
              ? { ...currentItem, isFavourite: newFavouriteStatus }
              : currentItem
          )
        );
      } else {
        console.error("Failed to update the medicine's favourite status.");
      }
    } catch (error) {
      console.error("Error updating favourite status:", error);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Liste des Produits Prochainement Périmés", 14, 22);

    const tableColumn = [
      "Nom",
      "Forme",
      "Quantité en stock",
      "Date de péremption",
    ];
    const tableRows = [];

    products.forEach((product) => {
      const productData = [
        product.name,
        product.forme,
        product.qte_stock,
        product.date_peremption,
      ];
      tableRows.push(productData);
    });

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
    });

    doc.save("produits_properimes.pdf");
  };

  return (
    <Flex flexDirection="column" py="110px">
    <Flex justifyContent="flex-end" mb="20px">
      <Button onClick={generatePDF} colorScheme="teal" width="200px">
      Télécharger en PDF
      </Button>
    </Flex>
    <div>
      {products.length > 0 ? (
        products.map((item, index) => (
          <Flex
            key={item._id}
            alignItems="center"
            mb="10px"
            position="relative"
          >
            <HistoryItem
              name={item.name}
              image={item.image || defaultImage}
              price={item.qte_stock}
              forme={item.forme}
              date_peremption={item.date_peremption}
              id={item.id}
            />
            <div
              onClick={() => handleFavouriteChange(item)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
              }}
            >
              {item.isFavourite ? (
                <AiFillStar color="gold" size={24} />
              ) : (
                <AiOutlineStar color="grey" size={24} />
              )}
            </div>
          </Flex>
        ))
      ) : (
        <p>No items to display</p>
      )}
    </div>
  </Flex>
  
  );
}
