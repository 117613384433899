import React, { useEffect, useState } from "react";
import axios from "axios";
import { Flex } from "@chakra-ui/react";

import HistoryItem from "views/admin/prodPlusVendus/components/HistoryItem";
import Card from "components/card/Card.js";
import defaultImage from "assets/img/meds.png";
import { API_URL } from "../../constants";
import {getAuthToken} from "../../helpers/helpers"

export default function WithoutDatePeremption() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = getAuthToken();
  
      try {
        const response = await axios.get(`${API_URL}/api/inventory`, {
          headers: {
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
          },
        });
        const filteredItems = response.data.filter(item => item.date_peremption === null);
        setItems(filteredItems);
      } catch (error) {
        console.error("Error making the request:", error);
      }
    };
  
    fetchData();
  }, []);
  
  

  return (
    <Flex flexDirection="column" py="100px">
      <Card p="0px">
        <div>
          {items.length > 0 ? (
            items.map((item, index) => (
              <Flex key={index} alignItems="center" mb="10px">

                <HistoryItem
                  name={item.specialite}
                  image={item.photo || defaultImage}
                  price={item.prix}
                  forme={item.forme}
                  id={item.med_id}
                  text="TND"
                />
              </Flex>
            ))
          ) : (
            <p>No items to display</p>
          )}
        </div>
      </Card>
    </Flex>
  );
}
