import React, { useState, useEffect } from "react";
import { Box, Text, Image, Grid, VStack, Tag, Wrap, Flex, ring } from "@chakra-ui/react";
import axios from "axios";
import { useProducts } from "../../../../contexts/providers/ProductProvider";
import { API_URL } from "../../../../constants";
import { getAuthToken } from "../../../../helpers/helpers";

function MedicDetails({ id }) {
  const { products } = useProducts();
  const medic = products.find((product) => product.med_id === id);
  const [selectedTrancheIndex, setSelectedTrancheIndex] = useState(null);
  const [selectedTranche, setSelectedTranche] = useState(null);
  const [medicData, setMedicData] = useState([]);

  const sectionTitleStyle = {
    margin: "0px 0px 0.5em",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "1.4993rem",
    color: "rgb(103, 139, 244)",
    lineHeight: "1.334",
  };

  const labelStyle = { fontWeight: "bold", fontSize: "1.2rem",  color: "#333"   , marginRight: "70px" };
  const valueStyle = { fontSize: "1rem", color: "#666" };
  const valueStyleAge = {  cursor: "pointer", fontSize: "0.85rem", color: "#666" ,letterSpacing: "0.00938em"};

  const conseilUtilisationPoints = medic?.conseil_utilisation
    ? medic.conseil_utilisation.split("+")
    : [];

  useEffect(() => {
    const token = getAuthToken();

    const fetchMedicData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/medicament_variants/${id}`, {
          headers: {
            "x-access-token": token,
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
        });
        setMedicData(response.data);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchMedicData();
  }, [id]);

  const generateTranches = () => {
    const uniqueTranches = new Map();
  
    medicData.forEach((variant) => {
      let ageMin = variant.min_age_enfant >= 12
        ? `${Math.ceil(variant.min_age_enfant / 12)} ans`
        : `${variant.min_age_enfant} mois`;
  
      let tranche_age;
      if (variant.max_age_enfant !== undefined && variant.max_age_enfant !== null) {
        const ageMax = variant.max_age_enfant >= 12
          ? `${Math.ceil(variant.max_age_enfant / 12)} ans`
          : `${variant.max_age_enfant} mois`;
        tranche_age = `De ${ageMin} à ${ageMax}`;
      } else {
        tranche_age = `À partir de ${ageMin}`;
      }
  
      const key = `${variant.min_age_enfant}-${variant.max_age_enfant}`;
  
      if (!uniqueTranches.has(key)) {
        uniqueTranches.set(key, {
          tranche_age,
          dose: variant.dose,
          frequence: variant.frequence,
          duree: variant.duree_traitement,
        });
      }
    });
  
    const tranches = Array.from(uniqueTranches.values());
  
    return tranches;
  };
  
  const posologieOptions = medicData.length > 0 ? generateTranches() : [];

  useEffect(() => {
    if (posologieOptions.length > 0 && selectedTrancheIndex === null) {
      setSelectedTrancheIndex(0);
      setSelectedTranche(posologieOptions[0]);
    }
  }, [posologieOptions, selectedTrancheIndex]);

  return medic ? (
    <Box width="500px" height="80vh" mx="auto" p={4} overflowY="auto" borderRadius="md" boxShadow="md">
    <Grid templateColumns="60% 40%" alignItems="center" mb={4} gap={4}>
      <Box>
        <Text fontSize="3xl" fontWeight="bold" mt={2}>{medic.specialite}</Text>
        <Text fontSize="xl" fontWeight="bold" color="rgb(244, 133, 12)">{medic.prix} TND</Text>
        <Wrap spacing={2} mt={2}>
          {medic.tags?.split("+").map((tag, index) => (
            <Tag key={index} bgColor="rgb(244, 201, 103)" color="black" fontWeight="bold">
              {tag.trim()}
            </Tag>
          ))}
        </Wrap>
      </Box>
      <Image
        src={medic.photo}
        alt={medic.specialite}
        boxSize="100%"
        width="100%"
        objectFit="cover"
        borderRadius="md"
      />
    </Grid>
    {posologieOptions.length > 1 && (
          <Flex wrap="wrap">
            {posologieOptions.map((tranche, index) => (
              <Box
                key={index}
                mr={3}
                p={2}
                style={valueStyleAge}
                fontWeight={selectedTrancheIndex === index ? "bold" : "normal"}
                textDecoration={selectedTrancheIndex === index ? "underline" : "none"}
                borderRadius="md"
                _hover={{ bg: "gray.300" }}
                onClick={() => {
                  setSelectedTrancheIndex(index);
                  setSelectedTranche(tranche);
                }}
              >
                {tranche.tranche_age}
              </Box>
            ))}
          </Flex>
        )}

      <Box bg="#f9f9fc" p={4} borderRadius="md">
        
        <Text {...sectionTitleStyle} color="rgb(103, 139, 244)" mb={2}>Posologie</Text>
        {selectedTranche && (
          <Box p={3} bg="white" borderRadius="md" boxShadow="sm">
            <Text><span style={labelStyle}>Dose par prise </span><span style={valueStyle}>{selectedTranche.dose || "-"}</span></Text>
            <Text><span style={labelStyle}>Fréquence </span><span style={valueStyle}>{selectedTranche.frequence || "-"}</span></Text>
            <Text><span style={labelStyle}>Durée du traitement </span><span style={valueStyle}>{selectedTranche.duree || "-"}</span></Text>
            <Text>
           <span style={labelStyle}>Conseil </span>
                 </Text>
                   <Box as="ul" pl={5} style={valueStyle}>  {conseilUtilisationPoints.map((point, index) => (
                        <Box as="li" key={index} mb={1}>
                         {point.trim() || "-"}
                        </Box> ))}
                   </Box>   
                </Box> )}
                  </Box>
      <Box bg="#fdeaea" p={4} mt={4} borderRadius="md">
        <Text {...sectionTitleStyle} color="rgb(103, 139, 244)" mb={2}>
          Mise en garde
        </Text>
        {medic.mise_en_garde ? (
          <Box as="ul" pl={4} style={{ listStyleType: "disc" }}>
            {medic.mise_en_garde.split("+").map((tag, index) => (
              <Text style={valueStyle} as="li" key={index}>
                {tag.trim()}
              </Text>
            ))}
          </Box>
        ) : (
          <Text>-</Text>
        )}
      </Box>
      <Box bg="#f9f9fc" p={4} mt={4} borderRadius="md">
        <Text {...sectionTitleStyle} color="rgb(103, 139, 244)" mb={2}>
          Profil patient
        </Text>
        <Grid templateColumns="1fr auto" gap={1}>
          {medic.diabete && (
            <>
              <Text>Diabète</Text>
              <Text color={medic.diabete === "oui" ? "green.500" : "red.500"}>
                {medic.diabete === "oui" ? "✓" : "✗"}
              </Text>
            </>
          )}
          {medic.hta && (
            <>
              <Text>HTA</Text>
              <Text color={medic.hta === "oui" ? "green.500" : "red.500"}>
                {medic.hta === "oui" ? "✓" : "✗"}
              </Text>
            </>
          )}
          {medic.allaitement && (
            <>
              <Text>Allaitement</Text>
              <Text color={medic.allaitement === "oui" ? "green.500" : "red.500"}>
                {medic.allaitement === "oui" ? "✓" : "✗"}
              </Text>
            </>
          )}
          {medic.grossesse && (
            <>
              <Text>Grossesse</Text>
              <Text color={medic.grossesse === "oui" ? "green.500" : "red.500"}>
                {medic.grossesse === "oui" ? "✓" : "✗"}
              </Text>
            </>
          )}
          <Text>Âge de prise</Text>
          <Text>{medic.profil_patient?.age || "-"}</Text>
        </Grid>
      </Box>

      <Box bg="#f9f9fc" p={4} mt={4} borderRadius="md">
        <Text {...sectionTitleStyle} color="rgb(103, 139, 244)" mb={2}>
          Spécifications du produit
        </Text>
        <VStack spacing={3} align="stretch">
          <Flex alignItems="center" my={1}>
            <Text style={labelStyle} mr={2}>Composition </Text>
            <Text style={valueStyle}>{medic.specifications?.composition || "-"}</Text>
          </Flex>
          <Flex alignItems="center" my={1}>
            <Text style={labelStyle} mr={2}>Forme galénique </Text>
            <Text style={valueStyle}>{medic.forme || "-"}</Text>
          </Flex>
          <Flex alignItems="center" my={1}>
            <Text style={labelStyle} mr={2}>Type </Text>
            <Text style={valueStyle}>{medic.medoc_ou_complement_ou_phyto || "-"}</Text>
          </Flex>
          <Flex alignItems="center" my={1}>
            <Text style={labelStyle} mr={2}>Prix </Text>
            <Text style={valueStyle}>{medic.prix} TND</Text>
          </Flex>
          <Flex alignItems="center" my={1}>
            <Text style={labelStyle} mr={2}>Dosage </Text>
            <Text style={valueStyle}>{medic.dose || "-"}</Text>
          </Flex>
          <Flex alignItems="center" my={1}>
            <Text style={labelStyle} mr={2}>Date de péremption </Text>
            <Text style={valueStyle}>{medic.date_peremption || "-"}</Text>
          </Flex>
        </VStack>
      </Box>
    </Box>
  ) : (
    <Text p={9}>Produit non trouvé</Text>
  );
}

export default MedicDetails;
