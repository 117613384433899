import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { API_URL } from "../../../constants";
import {
  Box,
  Button,
  //Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";

import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/logo.png";

import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function SignIn() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePassChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSubmit = async (event) => {
    const data = { email, password };
    try {
      const res = await axios.post(
        `${API_URL}/api/auth/signin`,
        data
      );

      if (res.status === 200 && res.data.accessToken) {
        //console.log(res.data);
        localStorage.setItem("token", res.data.accessToken);
        sessionStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("UserID", res.data.id);
        sessionStorage.setItem("UserID", res.data.id);
        const decoded = jwtDecode(res.data.accessToken);
        localStorage.setItem("username", decoded.username);
        history.push("/admin");
      } else {
        throw new Error("Unexpected response from the server.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("Invalid login credentials. Please try again.");
      } else {
        console.log(error);
        alert("Login failed, please try again !");
      }
    }
    /* console.log("email", email);
    console.log("password", password); */
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color="#22356D" fontSize="36px" mb="10px">
            Connexion
          </Heading>
          <Text mb="36px" ms="4px" color="gray" fontWeight="400" fontSize="md">
            Veuillez saisir vos coordonnées !
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color="#FFFFF"
              mb="8px"
            >
              Email<Text color="red">*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              value={email}
              onChange={handleEmailChange}
              mb="24px"
              fontWeight="500"
              size="lg"
              borderColor="gray"
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color="#FFFFF"
              display="flex"
            >
              Mot de passe<Text color="red">*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={password}
                onChange={handlePassChange}
                borderColor="gray"
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            {/* <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                  borderColor="gray"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color="#FFFFF"
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>

              <NavLink to="/auth/forgot-password">
                <Text color="#678BF4" fontSize="sm" w="124px" fontWeight="500">
                  Forgot password?
                </Text>
              </NavLink>
            </Flex> */}
            <form>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                backgroundColor="#22356D"
                onClick={handleSubmit}
              >
                Se connecter
              </Button>
            </form>
          </FormControl>

          {/* <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color="#FFFFF" fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/auth/sign-up">
                <Text color="#678BF4" as="span" ms="5px" fontWeight="500">
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
