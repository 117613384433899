import {Button, Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React, { useEffect, useState } from "react";
import { MdBarChart } from "react-icons/md";
import { RiArrowUpSFill } from "react-icons/ri";
import axios from "axios";
import { API_URL } from "../../../../constants";
import {getAuthToken} from "../../../../helpers/helpers"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";


export const lineChartOptionsTotalSpent = {
  chart: {
    toolbar: {
      show: false,
    },
    dropShadow: {
      enabled: true,
      top: 13,
      left: 0,
      blur: 10,
      opacity: 0.1,
      color: "#4318FF",
    },
  },
  colors: ["#4318FF", "#39B8FF"],
  markers: {
    size: 0,
    colors: "white",
    strokeColors: "#7551FF",
    strokeWidth: 3,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    discrete: [],
    shape: "circle",
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    type: "line",
  },
  xaxis: {
    type: "valeric",
    categories: [
      "JAN",
      "FEV",
      "MAR",
      "AVR",
      "MAI",
      "JUIN",
      "JUIL",
      "AOU",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    labels: {
      style: {
        colors: "#A3AED0",
        fontSize: "12px",
        fontWeight: "500",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  legend: {
    show: false,
  },
  grid: {
    show: false,
    column: {
      color: ["#7551FF", "#39B8FF"],
      opacity: 0.5,
    },
  },
  color: ["#7551FF", "#39B8FF"],
};

export default function TotalSpent({ selectedDate, ...rest }) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");

  const [count, setCount] = useState(0);
  const [monthlyCounts, setMonthlyCounts] = useState([]);
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [startMonth, setStartMonth] = useState(new Date().getMonth() - 11); // Affiche les 12 derniers mois

  const months = [
    "JAN", "FEV", "MAR", "AVR", "MAI", "JUIN", "JUIL", "AOU", "SEP", "OCT", "NOV", "DEC",
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = getAuthToken();

      // Fetch the data from both APIs
      const response1 = axios
        .get(`${API_URL}/api/track/action/AFFICHER_TRAITEMENT`, {
          headers: { 
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
           },
        })
        .catch((error) => {
          console.error("Error fetching AFFICHER_TRAITEMENT:", error);
          return { data: [] };
        });

      const response2 = axios
        .get(`${API_URL}/api/cart`, {
          headers: { 
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
           },
        })
        .catch((error) => {
          console.error("Error fetching cart:", error);
          return { data: [] };
        });

      try {
        const [res1, res2] = await Promise.all([response1, response2]);

        // Filter the results based on `selectedDate`
        const filteredData1 = res1.data.filter((item) => {
          const createdAt = new Date(item.created_at).toISOString().split("T")[0];
          return !selectedDate || createdAt === selectedDate;
        });

        const filteredData2 = res2.data.filter((item) => {
          const createdAt = new Date(item.created_at).toISOString().split("T")[0];
          return !selectedDate || createdAt === selectedDate;
        });

        const counts = Array(12).fill(0);
        const revenue = Array(12).fill(0);

        filteredData1.forEach((item) => {
          const date = new Date(item.created_at);
          const monthIndex = (date.getFullYear() - startYear) * 12 + date.getMonth() - startMonth;
          if (monthIndex >= 0 && monthIndex < 12) {
            counts[monthIndex]++;
          }
        });

        filteredData2.forEach((item) => {
          const date = new Date(item.created_at);
          const monthIndex = (date.getFullYear() - startYear) * 12 + date.getMonth() - startMonth;
          if (monthIndex >= 0 && monthIndex < 12) {
            item.items.forEach((item) => {
              if (item.medicamentDetails) {
                revenue[monthIndex] += item.medicamentDetails.prix * item.quantity;
              }
            });
          }
        });

        setMonthlyCounts(counts);
        setMonthlyRevenue(revenue);
        setCount(filteredData1.length);
      } catch (error) {
        console.error("Error making the request:", error);
      }
    };

    fetchData();
  }, [selectedDate, startYear, startMonth]);

  const handlePrevious = () => {
    setStartMonth((prev) => {
      if (prev === 0) {
        setStartYear((year) => year - 1);
        return 11;
      }
      return prev - 1;
    });
  };

  const handleNext = () => {
    setStartMonth((prev) => {
      if (prev === 11) {
        setStartYear((year) => year + 1);
        return 0;
      }
      return prev + 1;
    });
  };

  const lineChartDataTotalSpent = [
    {
      name: "Revenues",
      data: monthlyRevenue,
    },
    {
      name: "Utilisations",
      data: monthlyCounts,
    },
  ];

  const displayedMonths = [...Array(12)].map((_, i) => {
    const date = new Date(startYear, startMonth + i);
    return months[date.getMonth()] + " " + date.getFullYear();
  });

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
        <Flex align="center" w="100%">
          <Text
            me="auto"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            lineHeight="100%"
          >
            Nombre d'utilisations totales
          </Text>
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Flex>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={textColor}
            fontSize="34px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            {count}
          </Text>
          <Flex align="center" mb="20px">
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              mt="4px"
              me="12px"
            >
              Nombre d'utilisations
            </Text>
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
            </Flex>
          </Flex>
        </Flex>
        <Box minH="260px" minW="75%" mt="auto">
          {monthlyCounts.length > 0 && monthlyRevenue.length > 0 && (
            <LineChart
              chartData={lineChartDataTotalSpent}
              chartOptions={{
                ...lineChartOptionsTotalSpent,
                xaxis: { ...lineChartOptionsTotalSpent.xaxis, categories: displayedMonths },
              }}
            />
          )}
          <Flex justify="space-between" w="100%" gap="10px">
          <Button
            onClick={handlePrevious}
            bg="gray.300"
            color="white"
            _hover={{ bg: "gray.400" }}
            _active={{ bg: "gray.500" }}
            borderRadius="20px"
            px="10px"
            minW="40px"
          >
            <Icon as={ChevronLeftIcon} boxSize={5} color="white" />
          </Button>

          <Button
            onClick={handleNext}
            bg="gray.300"
            color="white"
            _hover={{ bg: "gray.400" }}
            _active={{ bg: "gray.500" }}
            borderRadius="20px"
            px="10px"
            minW="40px"
          >
            <Icon as={ChevronRightIcon} boxSize={5} color="white" />
          </Button>


        </Flex>
        </Box>
      </Flex>
    </Card>
  );
}
