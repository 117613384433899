import React, { useEffect, useState } from "react";
import axios from "axios";
import { Flex, Checkbox } from "@chakra-ui/react";

import HistoryItem from "views/admin/prodPlusVendus/components/HistoryItem";
import Card from "components/card/Card.js";
import defaultImage from "assets/img/meds.png";
import { API_URL } from "../../constants";
import {getAuthToken} from "../../helpers/helpers"

export default function Favoris() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = getAuthToken();

      try {
        const response = await axios.get(
          `${API_URL}/api/favorite_medicaments`,
          {
            headers: {
              "x-access-token": token,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setItems(response.data);
      } catch (error) {
        console.log("Error making the request:", error);
      }
    };

    fetchData();
  }, []);

  const handleFavouriteChange = async (item) => {
    const token = getAuthToken();

    try {
      const response = await axios.post(
        `${API_URL}/api/update_medicine`,
        {
          medicineId: item._id,
          isFavourite: false,
        },
        {
          headers: {
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      //console.log("API Response:", response.data);

      if (response.status === 200) {
        setItems((prevItems) =>
          prevItems
            .map((currentItem) =>
              currentItem._id === item._id
                ? {
                    ...currentItem,
                    isFavourite: false,
                  }
                : currentItem
            )
            .filter((currentItem) => currentItem.isFavourite)
        );
      } else {
        console.log("Failed to update the medicine's favourite status.");
      }
    } catch (error) {
      console.error("Error updating favourite status:", error);
    }
  };

  return (
    <Flex flexDirection="column" py="100px">
      <Card p="0px">
        <div>
          {items.length > 0 ? (
            items.map((item, index) => (
              <Flex key={index} alignItems="center" mb="10px">
                <Checkbox
                  defaultChecked
                  isChecked={item.isFavourite}
                  onChange={() => handleFavouriteChange(item)}
                  // mr="-10"
                  p="10px"
                />
                <HistoryItem
                  name={item.specialite}
                  image={item.photo || defaultImage}
                  price={item.prix}
                  forme={item.forme}
                  date_peremption={item.date_peremption}
                  id={item.med_id}
                  text="TND"
                />
              </Flex>
            ))
          ) : (
            <p>No items to display</p>
          )}
        </div>
      </Card>
    </Flex>
  );
}
