import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import { API_URL } from "./constants";
import {getAuthToken} from "./helpers/helpers"
import { ProductProvider } from "./contexts/providers/ProductProvider";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isTokenValid, setIsTokenValid] = useState(null);
  const token = getAuthToken()
  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/auth/verify`,
          {
            headers: {
              "x-access-token": token,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 201 && response.data.decoded) {
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      } catch (error) {
        setIsTokenValid(false);
      }
    };
    if (token) {
      verifyToken();
    } else {
      setIsTokenValid(false);
    }
  }, [token]);
  if (isTokenValid === null) {
    return <div>Chargement...</div>;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return isTokenValid ? (
          <ProductProvider>
          <Component {...props} />
          </ProductProvider>

        ) : (
          <Redirect to="/auth" />
        );
      }}
    />
  );
};
export default ProtectedRoute;
